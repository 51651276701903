import * as React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

const TechnologyFirst = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "why-latam.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;
  return (
    <div className="container mx-auto flex flex-col">
      <h2 className="text-3xl md:text-5xl py-8 md:py-16 px-4 text-left font-thin tracking-tighter box-border text-blue-400">
        A <span className="text-blue-300">cross-functional approach</span> for
        LATIN AMERICA
      </h2>

      <div className="flex flex-col md:flex-row text-blue-300 shadow-md bg-blue-800 overflow-hidden">
        {/* <div className="h-16 md:flex md:w-2/12 md:h-96"> */}
        <Img
          className="flex md:w-3/12 object-cover"
          fluid={imageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="Bogota, Colombia"
        />

        <div className="flex flex-col justify-between gap-4 p-8 md:w-5/12 bg-blue-800">
          <h3 className="flex flex-col md:flex-row uppercase text-xl md:text-3xl font-bold text-white">
            <span>WHY</span>
            <span className="md:pl-1">LATAM</span>
          </h3>
          <p>
            With a population of 650 million, LATAM is a fascinating market. We
            work hard to integrate resources in more than 15 countries.
          </p>
          <p>
            With large metropolises such as Sao Paulo, Mexico City or Buenos
            Aires and a regional average Internet penetration rate of 65% the
            possibilities are limitless.
          </p>
          <p> Conexum is ready to support a fast expansion of your business.</p>
          <Link
            to="/who-we-are"
            className="text-white font-bold underline cursor-pointer"
          >
            Learn more
          </Link>
        </div>

        <div className="flex flex-col justify-between p-8 md:w-5/12 md:border-l-2 md:border-blue-100 border-opacity-50 gap-4 bg-blue-700">
          <h3 className="flex flex-col md:flex-row uppercase text-xl md:text-3xl font-bold text-white">
            <span className="lg:pl-1">UNLIMITED</span>
            <span className="font-extralight lg:pl-1">POSSIBILITIES</span>
          </h3>
          <p>
            We have created a powerful world class IaaS platform in Latin
            America, developed strong bonds with local IT providers, ISPs and
            business, learned for years about the market, and most importantly:
          </p>
          <p>
            We have assembled an unstoppable and proactive cross-border team
            made up of exceptional people, ready for support a quick landing and
            operations of global companies in LATAM.
          </p>
          <Link
            to="/who-we-are"
            className="text-white font-bold underline cursor-pointer"
          >
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TechnologyFirst;
