import * as React from "react";
import { Link } from "gatsby";

// markup
const CallToAction = () => {
  return (
    <Link
      to="/careers"
      className="sm:h-8 
      inline-flex 
      items-center 
      text-white 
      bg-gray-900 
      rounded-full 
      p-1 pr-2 
      text-base lg:text-sm xl:text-base 
      transition duration-500 ease-in-out 
      hover:text-gray-200 hover:bg-blue-600 hover:border-2"
    >
      <span className="flex text-center px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-blue-600 rounded-full">
        We're hiring!
      </span>
      <span className="ml-4 text-xs md:text-sm">Visit our careers page</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="ml-1.5 w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5l7 7-7 7"
        ></path>
      </svg>
    </Link>
  );
};
export default CallToAction;
