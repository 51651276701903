import React from "react";
import { Line } from "@ant-design/charts";

const Chart = () => {
  const data = [
    { Year: "2017", Countries: 2 },
    { Year: "2018", Countries: 4 },
    { Year: "2019", Countries: 8 },
    { Year: "2020", Countries: 10 },
    { Year: "2021", Countries: 14 },
  ];

  const config = {
    data,
    height: 400,
    xField: "Year",
    yField: "Countries",
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
  };
  return <Line {...config} />;
};

export default Chart;
