import * as React from "react";
import Highlight from "./Highlight";
import ReachEverywhereImg from "../images/reach-everywhere.jpeg";
import StraightToTheHeartImg from "../images/straight-to-the-heart.jpeg";
import InstantSpeedImg from "../images/instant-speed.jpeg";

// markup
const Presence = (props) => {
  return (
    <div
      className={`container mx-auto flex flex-col md:gap-8 md:py-16 md:px-4 ${props.textColor}`}
    >
      <div className="flex flex-col lg:w-2/3 gap-4 md:gap-8 p-8 md:pt-8 md:px-0 md:self-center">
        <h3
          className={`flex flex-col md:flex-row uppercase text-4xl md:text-5xl font-extrabold ${props.textColor} md:justify-center`}
        >
          Presence{" "}
          <span className={`${props.textSecondaryColor} font-extralight`}>
            PLATFORM
          </span>
        </h3>
        <p className="flex text-xl lg:w-5/6 self-center">
          PRESENCE© is a large network with Points of Presence in datacenters
          throughout Latin America. From this solid platform we provide access
          to high quality and standardized IaaS solutions ready for a quick
          deployment.
        </p>
      </div>
      <div className="flex max-w-6xl self-center">
        <Highlight
          title="REACH"
          subtitle="EVERYWHERE"
          aligned="start"
          imgAlt="Fiber cables"
          imgSrc={ReachEverywhereImg}
        >
          With more than 14 points of presence in the most important cities of
          Latin America, expand your operations easily and get where your
          clients really need you.
        </Highlight>
      </div>
      <div className="flex max-w-6xl self-center">
        <Highlight
          title="STRAIGHT"
          subtitle="TO THE HEART"
          aligned="center"
          imgAlt="Mexico City"
          imgSrc={StraightToTheHeartImg}
        >
          We work very hard to get the most direct routes to connect Latin
          America with the world. Reach the heart of the markets where your
          customers are, wherever it is.
        </Highlight>
      </div>
      <div className="flex max-w-6xl self-center">
        <Highlight
          title="INSTANT"
          subtitle="SPEED"
          aligned="end"
          imgAlt="Light trails"
          imgSrc={InstantSpeedImg}
        >
          Our agreements with hundreds of providers offer a unique local and
          international experience for our clients, always seeking to offer the
          lowest latency possible.
        </Highlight>
      </div>
    </div>
  );
};

export default Presence;
