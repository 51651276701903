import React from "react";
import CallToAction from "./CallToAction";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const Hero = () => {
  const { heroQuery } = useStaticQuery(
    graphql`
      query {
        heroQuery: file(relativePath: { eq: "hero-bg.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 70
              webpOptions: { quality: 60 }
            )
          }
        }
      }
    `
  );
  const heroImage = getImage(heroQuery);

  return (
    <BgImage
      image={heroImage}
      className="w-screen h-5/6 xl:h-full"
      alt="CX Remote week - Isla Mujeres, Mexico, 2021"
    >
      <div className="flex flex-col justify-center container mx-auto md:py-36">
        <div className="p-4 py-16">
          <CallToAction />
          <h1 className="w-2/3 md:w-3/6 font-mulish mt-6 md:mt-4 tracking-tight font-semibold text-white sm:mt-5 sm:leading-none lg:mt-6 text-3xl md:text-4xl lg:text-5xl">
            <span className="md:block text-shadow-lg">
              Simplyfying expansion, operations and
              <span className="text-blue-300 font-bold"> innovation</span> in
              Latin America
            </span>
          </h1>
        </div>
      </div>
    </BgImage>
  );
};
export default Hero;
