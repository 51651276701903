import * as React from "react";

const Stat = (props) => {
  return (
    <h3 className="flex flex-col text-center justify-center">
      <span className="flex font-black text-6xl md:text-7xl self-center text-blue-800">
        {props.number}
      </span>
      <span className="flex text-lg md:text-2xl self-center">
        {props.title}
      </span>
      <span className="flex font-extralight text-md self-center">
        {props.text}
      </span>
    </h3>
  );
};

export default Stat;
