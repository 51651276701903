import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const WeDevelop = () => {
  const { heroQuery } = useStaticQuery(
    graphql`
      query {
        heroQuery: file(relativePath: { eq: "team.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 70
              webpOptions: { quality: 60 }
            )
          }
        }
      }
    `
  );
  const heroImage = getImage(heroQuery);

  return (
    <div className="mx-auto">
      <BgImage
        image={heroImage}
        className="w-screen h-96"
        alt="CX Remote week - Isla Mujeres, Mexico, 2021"
      >
        <div className="flex font-extralight flex-col justify-center relative text-center text-xl md:text-3xl items-center gap-8">
          <h2 className="bg-white rounded-md py-16 absolute opacity-70 top-24 md:w-1/2">
            <span className="font-semibold invisible">
              We develop ecosystems that strengthen collaborations
            </span>
            <span className="invisible">
              , in the pursuit of continuous improvement and excellence for our
              clients.
            </span>
            <Link
              to="/who-we-are"
              className="font-bold cursor-pointer invisible"
            >
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 text-base opacity-100">
                KNOW US
              </button>
            </Link>
          </h2>
        </div>
        <div className="flex font-extralight flex-col justify-center relative text-center text-xl md:text-3xl px-8 items-center md:px-36 gap-8">
          <h2 className=" rounded-md p-10 absolute md:w-1/2 top-24">
            <span className="font-semibold">
              We develop ecosystems that strengthen collaborations
            </span>
            , in the pursuit of continuous improvement and excellence for our
            clients.
            <Link
              to="/who-we-are"
              className="font-bold cursor-pointer flex justify-center mt-2"
            >
              <button className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-1 px-3 md:text-base opacity-100">
                KNOW US
              </button>
            </Link>
          </h2>
        </div>
      </BgImage>
    </div>
  );
};
export default WeDevelop;
