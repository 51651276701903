import * as React from "react";

const Highlight = (props) => {
  return (
    <div
      className={`shadow-lg flex border-b-2 border-white md:border-0 md:p-8 justify-${props.aligned}`}
      style={{
        backgroundImage: `url(${props.imgSrc})`,
        backgroundPosition: `center`,
      }}
    >
      <div className="flex flex-col p-8 bg-blue-800 shadow-lg lg:w-1/2 gap-4 z-50">
        <h2 className="flex flex-col md:flex-row uppercase text-xl md:text-3xl font-bold text-white">
          {props.title}
          <span className="font-extralight md:pl-1 text-white ">
            {props.subtitle}
          </span>
        </h2>
        <p className="flex text-gray-300">{props.children}</p>
      </div>
    </div>
  );
};

export default Highlight;
