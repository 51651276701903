import * as React from "react";
import Chart from "../Chart";
import Stat from "../StatBar/Stat";

// markup
const StatBar = (props) => {
  return (
    <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 row-span-3 gap-8 px-8">
      <div className="bg-gray-100 p-8 md:p-16 row-span-2 col-span-2 rounded-lg w-full">
        <h3 className="text-blue-900 text-4xl font-bold pt-2 pl-0 pb-8">
          {props.title}
        </h3>
        <Chart />
      </div>

      <div className="bg-gray-100 rounded-lg flex justify-center col-span-full md:col-span-1">
        <div className="flex flex-col p-8 md:p-16 self-center">
          <Stat
            number="4"
            title="YEARS"
            text="Providing solutions in Latin America"
          />
        </div>
      </div>

      <div className="bg-gray-100 rounded-lg flex justify-center col-span-full md:col-span-1">
        <div className="flex flex-col p-8 md:p-16 self-center">
          <Stat
            number="14"
            title="POINTS OF PRESENCE"
            text="In strategic locations"
          />
        </div>
      </div>
    </div>
  );
};

export default StatBar;
