import * as React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import StatBar from "../components/StatBar/StatBar";
import TechnologyFirst from "../components/TechnologyFirst";
import Footer from "../components/Footer";
import Presence from "../components/Presence";
import latamLogo from "../images/iconFromLatam.svg";
import WeDevelop from "../components/WeDevelop";
import TwoColumn from "../components/TwoColumn";

// markup
const IndexPage = () => {
  return (
    <>
      <main className="font-mulish w-full">
        <title>Conexum - Fueling innovation</title>
        <div>
          <header
            id="header"
            className="bg-blue-900 transition-all border-b-8 border-blue-300"
          >
            <Navbar />
          </header>
          <section id="hero" className="">
            <Hero />
          </section>
        </div>
      </main>
      <section id="technology-first" className="bg-blue-900 md:pb-16">
        <TechnologyFirst />
      </section>
      <section id="presence-platform" className="bg-gray-50 shadow-inner">
        <Presence
          textColor="text-gray-600"
          textSecondaryColor="text-gray-500"
        />
      </section>
      <section id="from-latam" className="bg-blue-900">
        <TwoColumn
          title="FROM LATIN AMERICA"
          subtitle="TO THE GLOBE"
          img={latamLogo}
          alt="LATAM logo"
        >
          Providing high-value technological solutions to facilitate and enhance
          operations in Latin America.
        </TwoColumn>
      </section>
      <section id="stat-bar" className="bg-blue-900 pb-8 md:pb-16">
        <StatBar title="Our growth" />
      </section>
      <section id="we-develop-us" className="bg-gray-50 shadow-inner py-16">
        <WeDevelop />
      </section>
      <section id="footer">
        <Footer />
      </section>
    </>
  );
};

export default IndexPage;
