import * as React from "react";

const TwoColumn = (props) => {
  return (
    <div className="container mx-auto flex flex-col md:flex-row justify-center">
      <div className="flex flex-col gap-4 lg:flex-row container mx-auto py-8 md:p-16 justify-evenly">
        <div className="w-full lg:w-1/2 items-center self-center p-8 md:p-0">
          <h2 className="flex uppercase text-4xl flex-col md:text-5xl font-bold text-white tracking-tighter">
            {props.title}
            <span className="font-light tracking-tighter">
              {props.subtitle}
            </span>
          </h2>
          <p className="text-gray-300 pt-4 text-lg">{props.children}</p>
        </div>
        <div className="w-full lg:w-1/2 hidden md:flex justify-center items-center">
          <img
            src={props.img}
            className="p-8 pt-0 md:p-0 h-64 w-64 md:h-64 md:w-64"
            alt={props.title + " " + props.subtitle}
          />
        </div>
      </div>
    </div>
  );
};

export default TwoColumn;
